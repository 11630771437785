 :root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #3B80F7;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 95%;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}


.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
}
.msger-send-btn {
  margin-left: 10px;
  background: #3B80F7;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: #215CC2;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

img.msg-img {
  width: 40px;
  height: 40px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}


.custom-class {
  background-color: #e06f6f;
}

.HomeBtn{
  width:200px;
  position: relative;
  top: 500px;
  left:500px;
  margin-left: 10px;
}
#greenbtn{
  color:green;
}
html, body {
  height:100%;
}

.container-fluid {
  height:90%;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}


.pageMenu .ant-menu-item{
  margin: 30px 0;
}

circle.node {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text { font: 12px sans-serif; }


.node--internal circle {
  fill: #555;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 3px;
  cursor: pointer;
}

._marker {
  fill: #ccc;
}

.red_marker {
  fill: red;
}

.filters {
  position: fixed;
  top: 65px;
  left: 72px;
  width: 160px;
  opacity: .35;
}
.filters:hover{
  opacity: 1;
  width: 258px;
}

.details {
  position: fixed;
  top: 70px;
  right: 10px;
  width: 350px;
  max-height: 80%;
  overflow-y: scroll;
}

#select-parent {
  position: fixed;
  top: 70px;
  right: 10px;
  width: 350px;
}

#graph {
  width: 100%;
  height: calc(100% - 48px);
  margin: auto;
  margin-top: -16px;
}

a {
  cursor: pointer;
}

ol li{
  list-style-type: none;
  color:blue;
}

#json-data {
  font-size: 12px;
  font-family: monospace;
  width: 100%;
  height: 250px;
}


.graph-container .node text {
  font-size: 0.9em;
  transition: font-size ease-in-out 0.2s;
}

.graph-container .node circle {
  fill: #fff;
  stroke: #153ba3;
  stroke-width: 3px;
  transition: r ease-in-out 0.2s;
}

.hidden {
  display: none;
}

/* multi parent */

.node:hover {
  cursor: pointer;
}

.node:hover text {
  font-size: 1em;
}

.node:hover circle {
  r: 12;
}

.node.selected circle {
  fill: lightcoral !important;
}

/* Chat window css */
.message {
  border-radius: 50px;
  margin: 0 15px 10px;
  padding: 15px 20px;
  position: relative;
  font-weight: bold;
}
.message.to {
  background-color: #2095FE;
  color: #fff;
  margin-left: 100px;
  text-align: right;
}
.message.from {
  background-color: #E5E4E9;
  color: #363636;
  margin-right: 100px;

}
.message.to + .message.to,
.message.from + .message.from {
margin-top: -10px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all .3s;
}
.dynamic-delete-button:hover {
  color: #777;
}

.numeric-input .ant-tooltip-inner {
  min-width: 32px;
  min-height: 37px;
}

.numeric-input .numeric-input-title {
  font-size: 14px;
}

.card-footer button + button {
  margin-left: 8px;
  margin-bottom: 5px;
}

.card-footer {
  /* padding-top: 10px;
  border-top: 1px solid #e8e8e8;
  margin-top: 10px; */
  margin-right: 16px;
}

/* iframe */
.instance-header .instance-header-first .scoped-ariadne {
  display: none;
}

._261r {
  align-items: center;
  display: flex;
  min-height: 0px !important;
  display: none;
  height: 0 !important;
}
#main-wrapper{
  margin-top:0
}

._261q {
  transform: translateY(20px);
}





/* ///////////// */
.msg-input{
  border: 0;
  background: #eee;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: none !important;
  border-radius:0;
  height:35px;
}
.witContent{
  margin: 3%
}
.tagger-suggested-header {
  overflow: hidden;
}
.tagger-suggested-text {
  font-size: 12px;
  color: #aaa;
  line-height: 1.6;
  padding-right: .7em;
  position: relative;
  display: inline-block;
  padding: 15px 0
}
.tagger-suggested-text:after {
  right: -1000px;
}
.tagger-suggested-text:after {
  content: '';
  height: 1px;
  width: 1000px;
  position: absolute;
  top: 50%;
  display: block;
  background-color: #ddd;
}

.formClass{
  background-color: #f5f6f7;
  border: 1px solid #dddfe2;
  border-radius: 0;
  padding: 20px;
}
.ant-select-search__field__wrap {

  border-radius:0
}
.ant-select-auto-complete.ant-select .ant-input {
  background: transparent;
  border-width: 0px;
  line-height: 1.5;
  height: 32px;
  border-radius:0
 
}

.formClass .ant-select-selection{
  border-radius: 0
}
.formClass .ant-btn{background: transparent;background-color:transparent;font-size: 10px}
.formClass .ant-btn:hover:h{background: transparent;background-color:transparent}

.sample-widget-grey-btn, .sample-widget-validate-btn {
  background: none;
  border: none;
  font-weight: 500;
  margin: 0;
  margin-right: 5px;
  outline: none;
  padding: 0;
  padding: 5px 10px;
  transition: all .1s ease-out;
}
.sample-widget-validate-btn {
  background: #90cb66;
}

.sample-widget-grey-btn, .sample-widget-validate-btn {
  background: none;
  border: none;
  font-weight: 500;
  margin: 0;
  margin-right: 5px;
  outline: none;
  padding: 0;
  padding: 5px 10px;
  transition: all .1s ease-out;
}



.formClass .ant-btn.disabled,.formClass .ant-btn[disabled], .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn.disabled.active, .ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.37) !important;
  background-color: #d8dee4 !important;
  border-color: #e6d8d8 !important;
  background: none;
  border: none;
  font-weight: 500;
  margin: 0;
  margin-right: 5px;
  outline: none;
  padding: 0;
  padding: 5px 10px;
  transition: all .1s ease-out;
  font-size: 12px;
  border-radius: 0;
}

.formClass .ant-btn{
  color: rgba(0, 0, 0, 0.877) !important;
  background-color: #90cb66 !important;
  border-color: #e6d8d8 !important;
  background: none;
  border: none;
  font-weight: 500;
  margin: 0;
  margin-right: 5px;
  outline: none;
  padding: 0;
  padding: 5px 10px;
  transition: all .1s ease-out;
  font-size: 12px !important;
  border-radius: 0;
}
.formClass .ant-btn > span {
  pointer-events: none;
  font-weight: 600;
  font-size: 13px;
}

button.ant-btn.linkBtn{
    border:0 !important;
    background-color:transparent !important;
    font-style:italic 
}
button.ant-btn.linkBtn span{
    pointer-events: none;
    font-weight: 600;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.65);

}
img.anticon{
  width: 23px;
  vertical-align: middle;
  opacity: .5;
}
.ant-menu-item-selected img{
  opacity: 1;
}